import React, { Component } from "react";
import { CartContext } from "./contexts/CartContext";

export default class OrderSummary extends Component {
  static contextType = CartContext;

  calcAmount = (cartData) => {
    let newData = [...cartData];
    newData = newData.map((item) => {
      item.totalPrice = item.price;
      item.options.map((option) => {
        item.totalPrice += option.topup;
      });
      return item;
    });
    let priceSum = newData.map((data) => {
      return data.totalPrice * data.qty;
    });
    if (priceSum.length !== 0) {
      let calcAmt = priceSum.reduce((acc, cur) => {
        return acc + cur;
      });

      return calcAmt;
    } else {
      return 0;
    }
  };

  calcTotalPrice = (item) => {
    let totalPrice = item.price;
    item.options.map((option) => {
      totalPrice = totalPrice + option.topup;
    });
    return totalPrice;
  };
  /*
   {!this.props.receipt ? (
                      <EditCart item={item}></EditCart>
                    ) : null}*/
  render() {
    let { cart, deleteItem, mode, deliveryFee, prevCart } = this.context;
    let cartToUse = cart;
    let feeToUse = deliveryFee;
    let discounted = 1;
    if (mode === "takeout") {
      discounted = 1;
    }
    if (this.props.receipt) {
      cartToUse = prevCart;
      feeToUse = this.context.receiptInfo.deliveryFee;
    }
    return (
      <div className="order-sum">
        {cartToUse.length !== 0
          ? cartToUse.map((item) => {
              return (
                <div className="order-item" key={item.id}>
                  <div className="item-details">
                    <span className="item-name">
                      {item.name}
                      {item.wineCollection ? (
                        <React.Fragment>
                          <br></br>
                          {item.wineCollection}
                        </React.Fragment>
                      ) : null}
                      {item.options.length !== 0
                        ? item.options.map((item) => {
                            return (
                              <React.Fragment>
                                <br></br>
                                <i>
                                  {item.type === "choice"
                                    ? item.value
                                    : item.value}
                                </i>
                              </React.Fragment>
                            );
                          })
                        : null}
                    </span>
                    <span className="item-price">
                      ${(this.calcTotalPrice(item) * item.qty).toFixed(2)}
                    </span>
                  </div>
                  <div className="item-qty">
                    <span className="sub-title">X{item.qty}</span>
                    {!this.props.receipt ? (
                      <button onClick={() => deleteItem(item.id)}>
                        remove
                      </button>
                    ) : null}
                  </div>
                  {item.edit ? (
                    <span style={{ fontStyle: "italic" }}>{item.edit}</span>
                  ) : null}
                </div>
              );
            })
          : null}

        <div className="order-total">
          <div className="sub-total">
            <span>subtotal</span>
            <span className="sub-total-amt">
              $
              {cartToUse !== undefined
                ? this.calcAmount(cartToUse).toFixed(2)
                : 0}
            </span>
          </div>
          {mode === "delivery" ? (
            <div className="sub-total">
              <span>delivery</span>
              <span className="sub-total-amt">${feeToUse.toFixed(2)}</span>
            </div>
          ) : null}
          <div className="sub-total">
            <span>GST</span>
            <span className="sub-total-amt">
              $
              {cartToUse !== undefined
                ? (
                    (this.calcAmount(cartToUse) * discounted + feeToUse) *
                    0.07
                  ).toFixed(2)
                : 0}
            </span>
          </div>
          <div className="total">
            <span>total</span>
            <span className="total-amt">
              $
              {cartToUse !== undefined
                ? (
                    (this.calcAmount(cartToUse) * discounted + feeToUse) *
                    1.07
                  ).toFixed(2)
                : 0}
            </span>
          </div>
          <div className="sub-total">
            <span>Service</span>
            <span className="sub-total-amt">
              {this.context.mode === "takeout" ? "Self Collection" : "Delivery"}
            </span>
          </div>
          {this.props.receipt ? (
            <React.Fragment>
              <hr></hr>
              <div className="sub-total">
                <span>Name</span>
                <span className="sub-total-amt">
                  {this.context.receiptInfo.name}
                </span>
              </div>
              <div className="sub-total">
                <span>Mobile</span>
                <span className="sub-total-amt">
                  {this.context.receiptInfo.mobile}
                </span>
              </div>
              <div className="sub-total">
                <span>Date</span>
                <span className="sub-total-amt">
                  {this.context.receiptInfo.orderDate}
                </span>
              </div>

              <div className="sub-total">
                <span>
                  {this.context.receiptInfo.mode === "delivery"
                    ? "Delivery"
                    : "Collection"}{" "}
                  Time
                </span>
                <span className="sub-total-amt">{this.context.time}</span>
              </div>

              {this.context.address !== "" ? (
                <div className="sub-total" style={{ paddingTop: "16px" }}>
                  <span className="sub-total-amt">
                    {this.context.receiptInfo.address}
                  </span>
                </div>
              ) : null}
            </React.Fragment>
          ) : null}
        </div>
      </div>
    );
  }
}
