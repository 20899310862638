import firebase from "firebase";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyAyNfM-E89XY4AmZtv-q5OWMcfevU0iAk0",
  authDomain: "ordering-eaa87.firebaseapp.com",
  databaseURL: "https://ordering-eaa87.firebaseio.com",
  projectId: "ordering-eaa87",
  storageBucket: "ordering-eaa87.appspot.com",
  messagingSenderId: "652770019030",
  appId: "1:652770019030:web:ee51bd85f4be618861ab77",
  measurementId: "G-P4YPXF30T9",
};

const fire = firebase.initializeApp(config);
export let auth = firebase.auth();
export let db = firebase.firestore();
export default fire;
