import React from "react";
import ReactDOM from "react-dom";
import { CartContext } from "./contexts/CartContext";
const modalRoot = document.getElementById("modal-root");

class Modal extends React.Component {
  static contextType = CartContext;
  render() {
    return ReactDOM.createPortal(
      <div
        style={{
          position: "fixed",
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.5)",
          zIndex: 4,
        }}
      >
        <div
          id="modal-pop"
          style={
            !this.context.isMobile
              ? {
                padding: this.props.timeSelect ? 0 : 16,
                backgroundColor: "#fff",
                borderRadius: this.props.timeSelect ? 0 : "8px",
                display: "inline-block",
                minHeight: "400px",
                position: "relative",
                maxWidth: "800px",
                maxHeight: window.innerHeight * 0.7,
                overflowY: "scroll",
                width: "100vw",
                justifySelf: "center",
                zIndex: 4,
              }
              : {
                padding: this.props.timeSelect ? 0 : 16,
                backgroundColor: "#fff",
                borderRadius: this.props.timeSelect ? 0 : "8px",
                display: "inline-block",
                minHeight: "300px",
                position: "relative",
                overflowY: "scroll",
                width: "100vw",
                height: "100vh",
                justifySelf: "center",
                zIndex: 4,
              }
          }
        >
          {!this.props.timeSelect ? (
            <button
              id="close"
              className="order-button"
              style={{
                border: "none",
                backgroundColor: "#fff",
                color: "#000",
                float: "right",
                fontWeight: "bold",
                cursor: "pointer",
                position: "absolute",
                right: 0,
              }}
              onClick={() => {
                this.props.onClose();
              }}
            >
              X
            </button>
          ) : null}
          {this.props.children}
        </div>
      </div>,
      modalRoot
    );
  }
}

export default Modal;
