import React, { Component } from "react";
import { message } from "antd";
import OrderSummary from "./OrderSummary";
import { CartContext } from "./contexts/CartContext";
import { MapPin } from "react-feather";
import {
  outlet,
  outletColor,
  outletContact,
  outletCoords,
  outletCap,
} from "../config";
import Pay from "./Pay";
import moment from "moment";

export default class Cart extends Component {
  static contextType = CartContext;
  state = {
    address: "",
    unit: "",
    modeChoice: "takeout",
  };
  componentDidMount() {
    this.context.amountCheck();
    if (this.context.mode === "takeout") {
      this.setState({ modeChoice: "takeout" });
    } else {
      this.setState({ modeChoice: "delivery" });
    }

    /* if (!this.context.later) {
      this.context.setMenu(false);
    } */
    if (!moment().add("h", 1).isBefore(this.context.dateValue)) {
      message.error(
        "Some time has passed since you selected your delivery time slot, please a select a new one."
      );
    }
  }

  handleChange = (event) => {
    const { value } = event.target;
    this.setState({ [event.target.id]: value });
  };

  enableDelivery = () => {
    this.setState({
      modeChoice: "delivery",
    });
  };
  enableTakeout = () => {
    this.setState({
      modeChoice: "takeout",
    });
  };

  distanceCheck = async () => {
    let selectedDate = this.context.dateValue.toDate();
    let startDate = new Date(2022, 3, 14, 0, 0);
    let endDate = new Date(2022, 3, 15, 23, 59);
    const response = await fetch(
      `https://developers.onemap.sg/commonapi/search?searchVal=${this.state.address}&returnGeom=Y&getAddrDetails=Y&pageNum=1`
    );
    const data = await response.json();

    if (data.results.length === 0 || this.state.address.length !== 6) {
      return false;
    } else {
      let custDistance = this.calcDistance(
        data.results[0].LATITUDE,
        data.results[0].LONGITUDE,
        outletCoords.lat,
        outletCoords.lng
      );
      let fee;
      if (custDistance <= 5000) {
        fee = 15.0;
      } else {
        let extraDistance = custDistance - 5000;
        let extraCharge = Math.ceil(extraDistance / 1000) * 1;
        if (extraCharge > outletCap) {
          extraCharge = outletCap;
        }
        fee = 15.0 + extraCharge;
      }
      return {
        address: data.results[0].ADDRESS + " " + this.state.unit,
        details: data.results[0],
        deliveryFee:
          selectedDate < endDate && selectedDate > startDate ? fee + 3 : fee,
        calcFee:
          selectedDate < endDate && selectedDate > startDate ? fee + 3 : fee,
      };
    }
  };

  calcDistance = (lat1, lon1, lat2, lon2) => {
    let R = 6371; // km (change this constant to get miles)
    let dLat = ((lat2 - lat1) * Math.PI) / 180;
    let dLon = ((lon2 - lon1) * Math.PI) / 180;
    let a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c;
    return Math.round(d * 1000);
  };

  render() {
    let { setMode, address } = this.context;
    return (
      <div className="cart">
        <div className="summary">
          <h1>YOUR ORDER</h1>
          <OrderSummary></OrderSummary>
          <div className="choice-options">
            {!this.context.deliveryDisabled ? (
              <button
                style={
                  this.state.modeChoice === "delivery"
                    ? {
                        backgroundColor: outletColor,
                        color: "#fff",
                      }
                    : null
                }
                className="sub-button"
                onClick={this.enableDelivery}>
                Delivery
              </button>
            ) : null}

            <button
              style={
                this.state.modeChoice === "takeout"
                  ? {
                      backgroundColor: outletColor,
                      color: "#fff",
                    }
                  : null
              }
              className="sub-button"
              onClick={() => {
                this.enableTakeout();
                setMode("takeout", "");
              }}>
              Self Collect
            </button>
          </div>
          {this.state.modeChoice === "delivery" ? (
            <React.Fragment>
              <h1>Delivery?</h1>
              <div className="delivery-add">
                <label>Input Your Postal Code</label>
                <input
                  type="text"
                  value={this.state.address}
                  onChange={this.handleChange}
                  id="address"></input>
                <label>Your Unit Number</label>
                <input
                  type="text"
                  value={this.state.unit}
                  onChange={this.handleChange}
                  id="unit"></input>
                <button
                  style={{ backgroundColor: outletColor }}
                  onClick={async () => {
                    if (
                      this.state.modeChoice === "delivery" &&
                      this.state.address === ""
                    ) {
                      message.error("Please enter your postal code.");
                    } else {
                      let check = await this.distanceCheck();
                      console.log(check);
                      if (!check) {
                        message.error(
                          "Please enter a valid postal code or whatsapp " +
                            outletContact +
                            " to order"
                        );
                      } else {
                        setMode("delivery", check);
                        this.setState({ address: "", unit: "" });
                        this.context.amountCheck();
                      }
                    }
                  }}>
                  CONFIRM
                </button>
                {address !== "" ? (
                  <div id="full-add">
                    <MapPin size={36}></MapPin>
                    <span>{address}</span>
                  </div>
                ) : null}
              </div>
            </React.Fragment>
          ) : null}
        </div>
        <Pay></Pay>
      </div>
    );
  }
}
