import React, { Component } from "react";
import OrderSummary from "./OrderSummary";
import { withRouter } from "react-router-dom";
import { CartContext } from "./contexts/CartContext";
import { outlet } from "../config";
class OrderReceipt extends Component {
  static contextType = CartContext;
  componentDidMount() {
    if (this.context.cart.length === 0 && this.context.prevCart.length === 0) {
      this.props.history.replace("/landing");
    }
  }

  render() {
    let selectedOutlet = outlet;
    return (
      <div id="order-receipt">
        <img src={require(`../images/${selectedOutlet}/receipt.png`)}></img>
        <h1>RECEIPT</h1>

        <OrderSummary receipt={true}></OrderSummary>

        <h5>Thank You For Your Support!</h5>
      </div>
    );
  }
}

export default withRouter(OrderReceipt);
