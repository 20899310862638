const TCS = "TCS";
const CMPB = "CM-PB";

export let outlet = CMPB;
export let outletCoords =
  outlet == "TCS"
    ? { lat: 1.388987, lng: 103.977813 }
    : { lat: 1.304738, lng: 103.808811 };
export let outletAddress =
  outlet == "TCS" ? "200 Netheravon Rd" : "7 Dempsey Rd, #01-05";
export let outletPostal = outlet == "TCS" ? "508529" : "249671";
export let outletContact = outlet == "TCS" ? "92302020" : "97766977";
export let outletColor = outlet == "TCS" ? "#bc9b5d" : "#000";
export let outletCap = outlet == "TCS" ? 10 : 10;
