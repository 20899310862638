import React, { Component } from "react";
import FilterResults from "react-filter-search";
import MenuItem from "./MenuItem";
import { Drawer, Button } from "antd";
import { HashLink as Link } from "react-router-hash-link";
import { CartContext } from "./contexts/CartContext";
import { outlet, outletColor } from "../config";
import scrollToComponent from "react-scroll-to-component";
export default class Drinks extends Component {
  static contextType = CartContext;
  state = {
    ageCheck: true,
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  scrollToMenu = (refer) => {
    scrollToComponent(refer, {
      offset: 0,
      align: "top",
      duration: 500,
    });
    this.onClose();
  };
  getCatData = (data, cat) => {
    return data
      .filter((item) => {
        return item.category === cat;
      })
      .sort((a, b) => {
        if (a.order < b.order) {
          return -1;
        } else {
          return 1;
        }
      });
  };
  render() {
    let normal = this.getCatData(this.props.drinks, "Water");
    let cocktails = this.getCatData(this.props.drinks, "Mocktails");
    let beers = this.getCatData(this.props.drinks, "Beers");
    let wines = this.getCatData(this.props.drinks, "Wines");
    let milkshakes = this.getCatData(this.props.drinks, "Milkshakes");
    let juices = this.getCatData(this.props.drinks, "Juices");
    let softDrinks = this.getCatData(this.props.drinks, "Soft Drinks");
    let coffee = this.getCatData(this.props.drinks, "Coffee");
    let ciders = this.getCatData(this.props.drinks, "TCS Premium Tea Cider");
    let selectedOutlet = outlet;
    let { ageCheck } = this.state;
    return (
      <div id="menu" className="drink-menu">
        <Drawer
          title="Basic Drawer"
          placement={this.state.placement}
          closable={false}
          onClose={this.onClose}
          visible={this.state.visible}
          headerStyle={{ visibility: "hidden" }}
          bodyStyle={{
            display: "flex",
            justifyContent: "space-around",
            flexDirection: "column",
            minHeight: "100vh",
            alignItems: "center",
          }}
        >
          <img
            src={require(`../images/${selectedOutlet}/${this.context.mode}.png`)}
          ></img>
          <div className="drawer-cat">
            <Link to="#normal" onClick={() => this.scrollToMenu(this.Normal)}>
              Non-alcoholic
            </Link>
            <Link
              to="#cocktails"
              onClick={() => this.scrollToMenu(this.Cocktails)}
            >
              Cocktails
            </Link>
            <Link to="#beer" onClick={() => this.scrollToMenu(this.Beers)}>
              Beers
            </Link>
            <Link to="#wines" onClick={() => this.scrollToMenu(this.Wines)}>
              Wines
            </Link>
            <Link to="#others" onClick={() => this.scrollToMenu(this.Others)}>
              Others
            </Link>
          </div>
        </Drawer>
        {ageCheck ? (
          <button
            style={{
              position: "fixed",
              display: "inlineBlock",
              textAlign: "center",
              width: "64px",
              height: "60px",
              bottom: "40px",
              right: "40px",
              zIndex: "2",
              backgroundColor: outletColor,
            }}
            onClick={this.showDrawer}
            id="floating-nav"
          >
            MENU
          </button>
        ) : null}


        {ageCheck ? (
          <React.Fragment>
            {" "}
            <div
              id="wines"
              ref={(div) => {
                this.Wines = div;
              }}
            >
              <h3 style={{ backgroundColor: outletColor }}>Wines</h3>
              {wines.map((item) => {
                return (
                  <MenuItem
                    update={this.props.update}
                    data={item}
                    key={item.id}
                    image={true}
                  ></MenuItem>
                );
              })}
            </div>

            <div
              id="beers"
              ref={(div) => {
                this.Beers = div;
              }}
            >
              <h3 style={{ backgroundColor: outletColor }}>Beers</h3>
              {beers.map((item) => {
                return (
                  <MenuItem
                    update={this.props.update}
                    data={item}
                    key={item.id}
                    image={true}
                  ></MenuItem>
                );
              })}
            </div>
            <div
              id="ciders"
              ref={(div) => {
                this.Ciders = div;
              }}
            >
              <h3 style={{ backgroundColor: outletColor }}>TCS Premium Tea Cider</h3>
              {ciders.map((item) => {
                return (
                  <MenuItem
                    update={this.props.update}
                    data={item}
                    key={item.id}
                    image={true}
                  ></MenuItem>
                );
              })}
            </div>
            <div
              id="cocktails"
              ref={(div) => {
                this.Cocktails = div;
              }}
            >
              <h3 style={{ backgroundColor: outletColor }}>Mocktails</h3>
              {cocktails.map((item) => {
                return (
                  <MenuItem
                    update={this.props.update}
                    data={item}
                    key={item.id}
                    image={true}
                  ></MenuItem>
                );
              })}
            </div>
            <div
              id="juices"
              ref={(div) => {
                this.Juices = div;
              }}
            >
              <h3 style={{ backgroundColor: outletColor }}>Juices</h3>
              {juices.map((item) => {
                return (
                  <MenuItem
                    update={this.props.update}
                    data={item}
                    key={item.id}
                    image={true}
                  ></MenuItem>
                );
              })}
            </div>
            <div
              id="milkshakes"
              ref={(div) => {
                this.Milkshakes = div;
              }}
            >
              <h3 style={{ backgroundColor: outletColor }}>Milkshakes</h3>
              {milkshakes.map((item) => {
                return (
                  <MenuItem
                    update={this.props.update}
                    data={item}
                    key={item.id}
                    image={true}
                  ></MenuItem>
                );
              })}
            </div>
            <div
              id="coffee"
              ref={(div) => {
                this.Coffee = div;
              }}
            >
              <h3 style={{ backgroundColor: outletColor }}>Coffee</h3>
              {coffee.map((item) => {
                return (
                  <MenuItem
                    update={this.props.update}
                    data={item}
                    key={item.id}
                    image={true}
                  ></MenuItem>
                );
              })}
            </div>
            <div
              id="softDrinks"
              ref={(div) => {
                this.SoftDrinks = div;
              }}
            >
              <h3 style={{ backgroundColor: outletColor }}>Soft Drinks</h3>
              {softDrinks.map((item) => {
                return (
                  <MenuItem
                    update={this.props.update}
                    data={item}
                    key={item.id}
                    image={true}
                  ></MenuItem>
                );
              })}
            </div>
            <div
              id="normal"
              ref={(div) => {
                this.Normal = div;
              }}
            >
              <h3 style={{ backgroundColor: outletColor }}>Water</h3>
              {normal.map((item) => {
                return (
                  <MenuItem
                    update={this.props.update}
                    data={item}
                    key={item.id}
                    image={true}
                  ></MenuItem>
                );
              })}
            </div>
          </React.Fragment>
        ) : null}

        <div style={{ height: "100px" }}></div>
      </div>
    );
  }
}
/*
<div id="check-age">
            <div className="age-check-box">
              <h4>You have to be 18 and over to view the alcholic drinks</h4>
              <button
                style={{ backgroundColor: outletColor }}
                onClick={() => this.setState({ ageCheck: true })}
              >
                Yes, I'm over 18 and over
              </button>
            </div>
          </div>
*/
