import React, { Component } from "react";
import NavBar from "./NavBar";
import { Route, Redirect } from "react-router-dom";
import Food from "./Food";
import Drinks from "./Drinks";
import Cart from "./Cart";
import Pay from "./Pay";
import ItemPage from "./ItemPage";
import { outlet } from "../config";
import { db } from "./firebase/Firebase";
import { CartContext } from "./contexts/CartContext";
import Desserts from "./Desserts";
import Modal from "./Modal";
import TimeSet from "./TimeSet";
export default class Menu extends Component {
  static contextType = CartContext;
  state = {
    food: [],
    items: [],
    isOpen: true,
  };
  componentDidMount() {
    let menuDoc =
      outlet === "TCS"
        ? "the-coastal-settlement"
        : "contemporary-melting-pot-bar";
    window.scrollTo(0, 0);
    db.collection("Menu")
      .where("outlet", "==", menuDoc)
      .get()
      .then((querySnapShot) => {
        let foodData = [];
        querySnapShot.forEach((item) => {
          let itemData = item.data();
          foodData.push({ ...itemData, id: item.id });
        });
        this.setState({
          food: foodData,
        });
      });
    /* 
    db.collection("Menu")
      .where("menu", "==", "jItfRdXQ09ChBsf1I8Nm")
      .get()
      .then((querySnapShot) => {
        let foodData = [];
        querySnapShot.forEach((item) => {
          console.log("checking");
          let data = item.data();
          if (data.type === "drinks") {
            console.log(data.category);
            console.log("found");
            foodData.push({ ...data, id: item.id });
          }
        });
        foodData.map((item) => {
          console.log("deleted");
          db.collection("Menu").doc(item.id).delete();
        });
      }); */

    if (this.context.timeslot === "") {
      this.props.history.replace({
        pathname: "/choice",
      });
    }
    /* this.state.items.map((item) => {
      db.collection("Menu").add(item);
    }); */
    /* let deleteDocs = db.collection("Menu").where("name", "==", "Carlsberg Alcohol Free");
    deleteDocs.get().then(function (querySnapShot) {
      querySnapShot.forEach(function (doc) {
        doc.ref.delete();
      });
    }); */
    /* let updateDocs = db.collection("Menu").where("category", "==", "Quick Meal");
    updateDocs.get().then(function (querySnapShot) {
      querySnapShot.forEach(function (doc) {
        doc.ref.update({
          options: [{ type: "choice", topup: 0, label: "Type", choices: ["regular", "vegetarian"] }],
        });
      });
    }); */
  }
  toggleModal = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  render() {
    let { loaded, selectedProfileData } = this.context;
    let food = this.state.food.filter((item) => {
      return (
        selectedProfileData.menus.findIndex((menu) => item.menu === menu) !== -1
      );
    });
    return (
      <div id="menu">
        {/* {!this.context.isMobile && this.state.isOpen ? (
          <Modal timeSelect={true} onClose={this.toggleModal}>
            <TimeSet onClose={this.toggleModal}></TimeSet>
          </Modal>
        ) : null} */}
        <NavBar onClose={this.toggleModal}></NavBar>
        <div style={{ height: this.context.isMobile ? 114 : 74 }}></div>
        {loaded ? (
          <Route
            path="/menu/food"
            render={(props) => <Food {...props} food={food}></Food>}></Route>
        ) : null}
        <Route
          path="/menu/cart"
          render={(props) => (
            <Cart
              {...props}
              clear={this.clearCart}
              update={this.updateCart}></Cart>
          )}></Route>
        <Route
          path="/menu/pay"
          render={(props) => <Pay {...props}></Pay>}></Route>
        <Route
          path="/menu/item-page"
          render={(props) => <ItemPage {...props}></ItemPage>}></Route>
      </div>
    );
  }
}
