import React, { Component } from "react";
import "react-tippy/dist/tippy.css";
import MenuItem from "./MenuItem";
import { outlet, outletColor } from "../config";
import { Drawer } from "antd";
import { HashLink as Link } from "react-router-hash-link";
import { CartContext } from "./contexts/CartContext";
import scrollToComponent from "react-scroll-to-component";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Observer from "@researchgate/react-intersection-observer";
import { db } from "./firebase/Firebase";
import { Tooltip } from "react-tippy";

export default class Food extends Component {
  static contextType = CartContext;
  state = {
    visible: false,
    placement: "right",
    homeCarousel: [],
    homeCarouselMobile: [],
  };

  componentDidMount() {
    let assetDoc = outlet === "TCS" ? "assetsTCS" : "assetsCMPB";
    db.collection("Settings")
      .doc(assetDoc)
      .onSnapshot((doc) => {
        this.setState(doc.data());
      });
  }

  handleIntersectChange = (e, id) => {
    let active;
    for (let property in this.state) {
      if (this.state[property]) {
        active = property;
      }
    }
    this.setState({ [id]: e.isIntersecting ? true : false });
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  scrollToMenu = (refer) => {
    scrollToComponent(refer, {
      offset: 0,
      align: "top",
      duration: 500,
    });
    this.onClose();
  };
  getCatData = (data, cat, cat2) => {
    return data
      .filter((item) => {
        return item.category === cat || item.category === cat2;
      })
      .sort((a, b) => {
        if (a.order < b.order) {
          return -1;
        } else {
          return 1;
        }
      });
  };
  render() {
    let selectedOutlet = outlet;
    let {
      isMobile,
      menus,
      menu,
      loaded,
      selectedMenuData,
      selectedProfileData,
    } = this.context;
    let menuStack = [];
    selectedProfileData.menus.map((menu) => {
      let selectedMenu = menus.find((item) => item.id === menu);
      menuStack = [...menuStack, ...selectedMenu.categories];
    });
    let food = [...this.props.food].filter((item) => {
      return item.available;
    });
    /*     let selectedMenu = { categories: [] };
    if (selectedMenuData) {
      selectedMenu = selectedMenuData;
      console.log(selectedMenu);
    } */
    /* let selectedMenu = { categories: [] };
    if (loaded && menu !== "") {
      selectedMenu = menus.find(item => item.id === menu) || { categories: [] };
    }
    console.log(selectedMenu) */

    return (
      <div id="menu" className="food-menu">
        {isMobile ? (
          <React.Fragment>
            <Drawer
              title="Basic Drawer"
              placement={this.state.placement}
              closable={false}
              onClose={this.onClose}
              visible={this.state.visible}
              headerStyle={{ visibility: "hidden" }}
              bodyStyle={{
                display: "flex",
                justifyContent: "space-around",
                flexDirection: "column",
                minHeight: "100vh",
                alignItems: "center",
              }}>
              <div className="drawer-cat">
                {loaded
                  ? menuStack.map((category) => {
                      return (
                        <Link
                          to={`#${category.name.toLowerCase()}`}
                          onClick={() => {
                            this.scrollToMenu(this[category.name]);
                          }}>
                          {category.name}
                        </Link>
                      );
                    })
                  : null}
              </div>
            </Drawer>
            <button
              style={{
                position: "fixed",
                display: "inlineBlock",
                width: "64px",
                height: "60px",
                textAlign: "center",
                bottom: "40px",
                right: "40px",
                zIndex: "2",
                backgroundColor: outletColor,
              }}
              onClick={this.showDrawer}
              id="floating-nav">
              MENU
            </button>
          </React.Fragment>
        ) : null}
        {isMobile ? (
          <Carousel autoPlay={true} infiniteLoop={true}>
            {this.state.homeCarouselMobile.map((banner) => {
              return (
                <div>
                  <img src={banner} />
                </div>
              );
            })}
          </Carousel>
        ) : (
          <Carousel autoPlay={true} infiniteLoop={true}>
            {this.state.homeCarousel.map((banner) => {
              return (
                <div>
                  <img src={banner} />
                </div>
              );
            })}
          </Carousel>
        )}

        <main>
          {this.context.timeslot && !isMobile ? (
            <div className="floating-menu">
              <h1>MENU</h1>
              {menuStack.map((category) => {
                return (
                  <Link
                    to={`#${category.name}`}
                    className={this.state[category.name] ? "active" : null}
                    onClick={() => {
                      this.scrollToMenu(this[category.name]);
                    }}>
                    {category.name}
                  </Link>
                );
              })}
            </div>
          ) : (
            <div></div>
          )}

          <div className="menu-container">
            {loaded
              ? menuStack.map((category) => {
                  return (
                    <Observer
                      threshold={0.25}
                      onChange={(event) =>
                        this.handleIntersectChange(event, category.name)
                      }>
                      <div
                        className="menu-item-grid"
                        ref={(div) => {
                          this[category.name] = div;
                        }}>
                        <h3 style={{ backgroundColor: outletColor }}>
                          {category.name}
                        </h3>
                        {this.getCatData(
                          food,
                          category.name,
                          category.name.toLowerCase()
                        ).map((item) => {
                          return (
                            <MenuItem
                              update={this.props.update}
                              data={item}
                              key={item.id}
                              image={true}></MenuItem>
                          );
                        })}
                      </div>
                    </Observer>
                  );
                })
              : null}
          </div>

          <div style={{ height: "100px" }}></div>
        </main>
      </div>
    );
  }
}
