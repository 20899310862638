import React, { Component } from "react";
import Menu from "./components/Menu";
import OrderReceipt from "./components/OrderReceipt";
import "antd/dist/antd.css";
import { Route, Redirect } from "react-router-dom";
import "./App.css";
import CartContextProvider from "./components/contexts/CartContext";
import TimeSet from "./components/TimeSet";

class App extends Component {
  render() {
    return (
      <CartContextProvider>
        <div className="App">
          <Route
            exact
            path="/"
            render={(props) => <Redirect {...props} to="/menu/food"></Redirect>}
          ></Route>
          <Route path="/menu" component={Menu}></Route>
          <Route path="/choice" component={TimeSet}></Route>
          <Route
            path="/receipt"
            render={(props) => <OrderReceipt {...props}></OrderReceipt>}
          ></Route>
        </div>
      </CartContextProvider>
    );
  }
}

export default App;
