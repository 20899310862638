import React, { Component } from "react";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { CartContext } from "./contexts/CartContext";
import { DatePicker } from "antd";
import moment from "moment";
const stripePromise = loadStripe("pk_live_pgasMXxTgDwL1cItA9YOQOcV");
//pk_test_FqEiZB2wNekj7Ikmi6ZrqQN4
//pk_live_pgasMXxTgDwL1cItA9YOQOcV
export default class Pay extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.onTimeChange = this.onTimeChange.bind(this);
    this.onOk = this.onOk.bind(this);
  }
  state = {
    name: "",
    mobile: "",
    email: "",
    time: moment.utc(new Date()).local(),
    dateTime: "",
  };
  onTimeChange = (value, dateString) => {
    console.log("Selected Time: ", value);
    console.log("Formatted Selected Time: ", dateString);
    this.setState({
      dateTime: dateString,
    });
  };

  onOk = (value) => {
    console.log("onOk: ", value);
  };
  componentDidMount() {
    if (this.context.mode === "delivery") {
      let newTime = moment()
        .add(1, "h")
        .utc()
        .local()
        .format("DD/MM/YY hh:mm a");
      this.setState({
        dateTime: newTime,
      });
    } else {
      let newTime = moment()
        .add(45, "m")
        .utc()
        .local()
        .format("DD/MM/YY hh:mm a");
      this.setState({
        dateTime: newTime,
      });
    }
  }

  onChange = (event) => {
    const { value } = event.target;
    this.setState({ [event.target.id]: value });
  };

  render() {
    return (
      <div className="pay">
        <h1>PAYMENT</h1>
        <div className="get-contact">
          <form>
            <div className="form-group">
              <label
                style={
                  this.state.checked && this.state.name == ""
                    ? { color: "red" }
                    : null
                }
              >
                Name*
              </label>
              <input
                required
                value={this.state.name}
                onChange={this.onChange}
                id="name"
                type="text"
              ></input>
            </div>
            <div className="form-group" id="mobile-no">
              <label
                style={
                  this.state.checked && this.state.mobile == ""
                    ? { color: "red" }
                    : null
                }
              >
                Mobile*
              </label>
              <input
                required
                value={this.state.mobile}
                onChange={this.onChange}
                id="mobile"
                type="number"
              ></input>
            </div>
            <div className="form-group">
              <label
                style={
                  this.state.checked && this.state.email == ""
                    ? { color: "red" }
                    : null
                }
              >
                Email*
              </label>
              <input
                required
                value={this.state.email}
                onChange={this.onChange}
                id="email"
                type="Email"
                pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
              ></input>
            </div>
            {/*<div className="form-group">
              <label>
                {this.context.mode === "takeout"
                  ? "Collection Date & Time"
                  : "Delivery Date & Time"}{" "}
              </label>
              <DatePicker
                showTime={{
                  use12Hours: true,
                  format: "hh:mm a",
                }}
                defaultValue={
                  this.context.mode === "takeout"
                    ? moment.utc(new Date()).local().add("m", 45)
                    : moment.utc(new Date()).local().add("h", 1)
                }
                onChange={this.onTimeChange}
                onOk={this.onOk}
                format={"DD/MM/YY hh:mm a"}
              />
              </div>*/}
          </form>
        </div>

        <div className="payment-box">
          <Elements stripe={stripePromise}>
            <CheckoutForm
              contact={{
                name: this.state.name,
                mobile: this.state.mobile,
                email: this.state.email,
              }}
            ></CheckoutForm>
          </Elements>
        </div>
      </div>
    );
  }
}
