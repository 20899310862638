import React, { Component } from "react";
import { CartContext } from "./contexts/CartContext";
import { outlet, outletColor } from "../config";
import { DatePicker, TimePicker, message, Radio } from "antd";
import { ArrowLeft } from "react-feather";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import { db } from "./firebase/Firebase";
import moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";

export default class TimeSet extends Component {
  static contextType = CartContext;
  state = {
    time: moment.utc(new Date()).local().add("h", 1),
    timeNow: moment.utc(new Date()).local().add("m", 45),
    time2: "",
    day: "",
    chosen: false,
    chosen2: false,
    storeIsActive: true,
    timeslots: {
      weekend: [
        {
          slot: "9:00am - 9:30am",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 9, minute: 0, second: 0 }),
        },
        {
          slot: "9:30am - 10:00am",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 9, minute: 30, second: 0 }),
        },
        {
          slot: "10:00am - 10:30am",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 10, minute: 0, second: 0 }),
        },
        {
          slot: "10:30am - 11:00am",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 10, minute: 30, second: 0 }),
        },
        {
          slot: "11:00am - 11:30am",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 11, minute: 0, second: 0 }),
        },
        {
          slot: "11:30am - 12:00pm",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 11, minute: 30, second: 0 }),
        },
        {
          slot: "12:00am - 12:30pm",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 12, minute: 0, second: 0 }),
        },
        {
          slot: "12:30pm - 1:00pm",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 12, minute: 30, second: 0 }),
        },
        {
          slot: "1:00pm - 1:30pm",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 13, minute: 0, second: 0 }),
        },
        {
          slot: "1:30pm - 2:00pm",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 13, minute: 30, second: 0 }),
        },
        {
          slot: "2:00pm - 2:30pm",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 14, minute: 0, second: 0 }),
        },
        {
          slot: "2:30pm - 3:00pm",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 14, minute: 30, second: 0 }),
        },
        {
          slot: "3:00pm - 3:30pm",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 15, minute: 0, second: 0 }),
        },
        {
          slot: "3:30pm - 4:00pm",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 15, minute: 30, second: 0 }),
        },
        {
          slot: "4:00pm - 4:30pm",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 16, minute: 0, second: 0 }),
        },
        {
          slot: "6:00pm - 6:30pm",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 18, minute: 0, second: 0 }),
        },
        {
          slot: "6:30pm - 7:00pm",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 18, minute: 30, second: 0 }),
        },
        {
          slot: "7:00pm - 7:30pm",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 19, minute: 0, second: 0 }),
        },
        {
          slot: "7:30pm - 8:00pm",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 19, minute: 30, second: 0 }),
        },
        {
          slot: "8:00pm - 8:30pm",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 20, minute: 0, second: 0 }),
        },
        {
          slot: "8:30pm - 9:00pm",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 20, minute: 30, second: 0 }),
        },
        {
          slot: "9:00pm - 9:30pm",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 21, minute: 0, second: 0 }),
        },
        {
          slot: "9:30pm - 10:00pm",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 21, minute: 30, second: 0 }),
        },
      ],
      weekday: [
        {
          slot: "11:00am - 11:30am",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 11, minute: 0, second: 0 }),
        },
        {
          slot: "11:30am - 12:00pm",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 11, minute: 30, second: 0 }),
        },
        {
          slot: "12:00am - 12:30pm",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 12, minute: 0, second: 0 }),
        },
        {
          slot: "12:30pm - 1:00pm",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 12, minute: 30, second: 0 }),
        },
        {
          slot: "1:00pm - 1:30pm",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 13, minute: 0, second: 0 }),
        },
        {
          slot: "1:30pm - 2:00pm",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 13, minute: 30, second: 0 }),
        },
        {
          slot: "2:00pm - 2:30pm",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 14, minute: 0, second: 0 }),
        },
        {
          slot: "2:30pm - 3:00pm",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 14, minute: 30, second: 0 }),
        },
        {
          slot: "3:00pm - 3:30pm",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 15, minute: 0, second: 0 }),
        },
        {
          slot: "3:30pm - 4:00pm",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 15, minute: 30, second: 0 }),
        },
        {
          slot: "4:00pm - 4:30pm",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 16, minute: 0, second: 0 }),
        },
        {
          slot: "6:00pm - 6:30pm",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 18, minute: 0, second: 0 }),
        },
        {
          slot: "6:30pm - 7:00pm",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 18, minute: 30, second: 0 }),
        },
        {
          slot: "7:00pm - 7:30pm",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 19, minute: 0, second: 0 }),
        },
        {
          slot: "7:30pm - 8:00pm",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 19, minute: 30, second: 0 }),
        },
        {
          slot: "8:00pm - 8:30pm",
          time: moment
            .utc(new Date())
            .local()
            .set({ hour: 20, minute: 0, second: 0 }),
        },
      ],
    },
    timeStep: 30,
    now: false,
    desktopLanding: "",
    mobileLanding: "",
  };
  componentDidMount() {
    //this.context.setMenu(false);
    let assetDoc = outlet === "TCS" ? "assetsTCS" : "assetsCMPB";
    let operationsDoc = outlet === "TCS" ? "operationsTCS" : "operationsCMPB";
    let weekdayDoc = outlet === "TCS" ? "weekday" : "weekdayCMPB";
    let weekendDoc = outlet === "TCS" ? "weekend" : "weekendCMPB";
    db.collection("Settings")
      .doc(assetDoc)
      .onSnapshot((doc) => {
        this.setState(doc.data());
      });
    db.collection("Settings")
      .doc(operationsDoc)
      .onSnapshot((doc) => {
        this.setState({
          storeIsActive: doc.data().storeIsActive,
        });
      });
    db.collection("Timings")
      .doc(weekdayDoc)
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log("exist");
          let { opening, closing, ranges } = doc.data();
          let selected = [];
          if (ranges.length !== 0) {
            ranges = ranges.map((range) => {
              range.start = moment(range.start.toDate());
              range.end = moment(range.end.toDate());
              return range;
            });
            ranges.map((range) => {
              selected = [
                ...selected,
                ...this.generateSlots(range.start, range.end, range.menu),
              ];
            });
          }
          this.setState({
            weekDay: {
              opening: moment({ hour: opening.hour, minute: opening.minute }),
              closing: moment({ hour: closing.hour, minute: closing.minute }),
              selected,
              ranges,
            },
          });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });

    db.collection("Timings")
      .doc(weekendDoc)
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log("exist");
          let { opening, closing, ranges } = doc.data();
          let selected = [];
          if (ranges.length !== 0) {
            ranges = ranges.map((range) => {
              range.start = moment(range.start.toDate());
              range.end = moment(range.end.toDate());
              return range;
            });
            ranges.map((range) => {
              selected = [
                ...selected,
                ...this.generateSlots(range.start, range.end, range.menu),
              ];
            });
          }
          this.setState({
            weekEnd: {
              opening: moment({ hour: opening.hour, minute: opening.minute }),
              closing: moment({ hour: closing.hour, minute: closing.minute }),
              selected,
              ranges,
            },
          });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }

  generateSlots = (start, stop, profile) => {
    let { timeStep } = this.state;
    let slots = [];
    let currentSlot = {
      start: start,
      end: start.clone().add(timeStep, "m"),
      label: `${start.format("HH:mm")} - ${start
        .clone()
        .add(timeStep, "m")
        .format("HH:mm")}`,
      profile: profile,
    };
    while (
      currentSlot.end.hour() * 100 + currentSlot.end.minute() <=
      stop.hour() * 100 + stop.minute()
    ) {
      let newSlot = { ...currentSlot };
      slots.push(newSlot);
      let nextSlot = { ...newSlot };
      nextSlot.start = nextSlot.end;
      nextSlot.end = nextSlot.start.clone().add(timeStep, "m");
      nextSlot.label = `${nextSlot.start.format(
        "HH:mm"
      )} - ${nextSlot.end.format("HH:mm")}`;
      currentSlot = nextSlot;
    }
    return slots;
  };

  toggleNow = () => {
    this.setState({ now: true });
  };
  toggleLater = () => {
    this.setState({ now: false });
  };
  onTimeChange = (value, dateString) => {
    this.setState({
      time: value,
      day: this.checkDay(value.day(), value.month(), value.date()),
    });
  };
  checkDay = (dayOfWeek, month, dayOfMonth) => {
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      return "weekEnd";
    } else if (month == 4 && dayOfMonth == 13) {
      return "weekEnd";
    } else if (month == 6 && dayOfMonth == 20) {
      return "weekEnd";
    } else if (month == 7 && dayOfMonth == 9) {
      return "weekEnd";
    } else if (month == 10 && dayOfMonth == 4) {
      return "weekEnd";
    } else {
      return "weekDay";
    }
  };
  onChange = (value, dateString) => {
    console.log("Selected Time: ", value);
    console.log("Formatted Selected Time: ", dateString);
    this.setState({
      time2: value,
    });
  };
  onSlotChange(e) {
    console.log(`radio checked:${e.target.value}`);
  }
  handleDayClick = (day, { selected, disabled }) => {
    if (disabled) {
      return;
    }
    let momentDay = moment(day);
    this.setState({
      date: day,
      time: momentDay,
      chosen2: true,
      day: this.checkDay(momentDay.day(), momentDay.month(), momentDay.date()),
    });
  };
  render() {
    let { day, chosen, chosen2, date, timeNow, time } = this.state;
    let { loaded, isMobile, menus, setSlot, setProfile, profiles } =
      this.context;
    let inAdvance = new Date();
    inAdvance.setDate(inAdvance.getDate() + 1);
    let daysToDisable =
      this.context.outlet === "TCS" ? {} : { daysOfWeek: [0, 5, 6] };
    return (
      <div
        id="choice"
        style={{
          background:
            this.state.desktopLanding !== ""
              ? !isMobile
                ? `url('${this.state.desktopLanding}')`
                : `url('${this.state.mobileLanding}')`
              : "#bc9b5d",
          boxShadow: "inset 0 0 0 1000px rgba(0,0,0,.7)",
        }}>
        {outlet === "TCS" ? (
          isMobile ? (
            chosen2 ? null : (
              <img
                className="landing-logo"
                src={require("../images/TCS/TCS Logo white.png")}
                alt=""
              />
            )
          ) : (
            <img
              className="landing-logo"
              src={require("../images/TCS/TCS Logo white.png")}
              alt=""
            />
          )
        ) : isMobile ? (
          chosen2 ? null : (
            <img
              className="landing-logo cmpb-inverse"
              src={require("../images/CM-PB/CM-PB black logo.png")}
              alt=""
            />
          )
        ) : (
          <img
            className="landing-logo cmpb-inverse"
            src={require("../images/CM-PB/CM-PB black logo.png")}
            alt=""
          />
        )}
        {/* {isMobile ? (
          chosen2 ? null : (
            <img
              className="landing-logo"
              src={require("../images/TCS/TCS Logo white.png")}
              alt=""
            />
          )
        ) : (
          <img
            className="landing-logo"
            src={require("../images/TCS/TCS Logo white.png")}
            alt=""
          />
        )} */}
        {/* {day !== "" && chosen2 ? (
          <button
            id="back-btn"
            onClick={() => this.setState({ day: "", chosen2: false })}>
            <ArrowLeft></ArrowLeft> Date Selection
          </button>
        ) : null} */}
        {!this.state.chosen ? (
          <div className="landing-box">
            {/* <img
              className="landing-b-test"
              src={require("../images/TCS/nav.png")}
              alt=""
            /> */}
            {/*             {isMobile ? (
              <img
                id="landing-banner-img"
                src={require("../images/TCS/Landing-page-2.jpg")}
                alt=""
              />
            ) : null} */}
            <div className="option">
              {!loaded ? (
                <ClipLoader color="#fff"></ClipLoader>
              ) : this.state.storeIsActive ? (
                <p>
                  No service charges.
                  <br></br>
                  Please select a timeslot at least 1 hour from now.
                  <button
                    style={{ backgroundColor: outletColor }}
                    onClick={() => {
                      this.setState({ chosen: true });
                    }}>
                    Select Date
                  </button>
                </p>
              ) : (
                <p>
                  Our online store is currently down for upgrading. We
                  appreciate your understanding.
                </p>
              )}
            </div>
          </div>
        ) : null}
        {/* {day !== "" && chosen2 ? (
          <div className="timeslots">
            {loaded
              ? this.state[day].ranges.map((range) => {
                  return (
                    <React.Fragment>
                      <span>
                        {
                          profiles.find((item) => item.id === range.profile)
                            .displayName
                        }
                      </span>
                      {this.generateSlots(
                        range.start,
                        range.end,
                        range.profile
                      ).map((slot, index) => {
                        return (
                          <button
                            key={`${range.menu}${index}`}
                            disabled={
                              slot.end.isBefore(timeNow) &&
                              timeNow.isSame(time, "day")
                            }
                            style={
                              slot.end.isBefore(timeNow) &&
                              timeNow.isSame(time, "day")
                                ? { cursor: "pointer", opacity: 0.5 }
                                : { cursor: "pointer" }
                            }
                            onClick={() => {
                             
                              let date1 = time;
                              let time2 = slot.end;
                              date1.set({
                                hour: time2.get("hour"),
                                minute: time2.get("minute"),
                              });
                              let setDate = time.toDate();
                              setSlot(slot);
                              setProfile(true, setDate, time, slot.profile);
                              this.props.history.replace({
                                pathname: "/menu/food",
                              });
                            }}>
                            {slot.label}
                          </button>
                        );
                      })}
                    </React.Fragment>
                  );
                })
              : null}

            
          </div>
        ) : null} */}
        {chosen ? (
          <div className="date-time-choice">
            {this.context.isMobile && !chosen2 ? (
              <div className="dates">
                {/* <h1 style={{ textAlign: "center" }}>Select Date</h1> */}
                <DayPicker
                  onDayClick={this.handleDayClick}
                  selectedDays={date}
                  onDayChange={(day) => console.log(day)}
                  showOverlay={true}
                  disabledDays={[{ before: inAdvance }, daysToDisable]}
                />
                {/* <button
                style={{ backgroundColor: outletColor }}
                onClick={() => {
                  if (day === "") {
                    message.warning("Please select a date!");
                  } else {
                    this.setState({ chosen2: true });
                  }
                }}>
                Select Time
              </button> */}
              </div>
            ) : null}
            {!this.context.isMobile ? (
              <div className="dates">
                {/* <h1 style={{ textAlign: "center" }}>Select Date</h1> */}
                <DayPicker
                  onDayClick={this.handleDayClick}
                  selectedDays={date}
                  onDayChange={(day) => console.log(day)}
                  showOverlay={true}
                  disabledDays={[{ before: inAdvance }, daysToDisable]}
                />
                {/* <button
                style={{ backgroundColor: outletColor }}
                onClick={() => {
                  if (day === "") {
                    message.warning("Please select a date!");
                  } else {
                    this.setState({ chosen2: true });
                  }
                }}>
                Select Time
              </button> */}
              </div>
            ) : null}
            {day !== "" && chosen2 ? (
              <div className="timeslots">
                {this.context.isMobile ? (
                  <button
                    id="back-btn"
                    onClick={() => this.setState({ chosen2: false })}>
                    <ArrowLeft></ArrowLeft> Date Selection
                  </button>
                ) : null}
                {loaded
                  ? this.state[day].ranges.map((range) => {
                      let today = this.state.date.getDate();
                      let todayMonth = this.state.date.getMonth();
                      let todayYear = this.state.date.getFullYear();
                      return (
                        <React.Fragment>
                          <span>
                            {
                              profiles.find((item) => item.id === range.profile)
                                .displayName
                            }
                          </span>
                          {this.generateSlots(
                            range.start.set({
                              year: todayYear,
                              month: todayMonth,
                              date: today,
                            }),
                            range.end.set({
                              year: todayYear,
                              month: todayMonth,
                              date: today,
                            }),
                            range.profile
                          ).map((slot, index) => {
                            console.log(slot);
                            return (
                              <button
                                key={`${range.menu}${index}`}
                                disabled={
                                  slot.end.isBefore(moment().add("h", 1)) &&
                                  timeNow.isSame(time, "day")
                                }
                                style={
                                  slot.end.isBefore(moment().add("h", 1)) &&
                                  timeNow.isSame(time, "day")
                                    ? { opacity: 0.5 }
                                    : { cursor: "pointer" }
                                }
                                onClick={() => {
                                  /* let date1 = this.state.time;
                      let time2 = slot.end;
                      date1.set({
                        hour: time2.get("hour"),
                        minute: time2.get("minute"),
                      });
                      let setDate = this.state.time.toDate();
                      this.context.setProfile(true, setDate, this.state.time); */
                                  let date1 = time;
                                  let time2 = slot.end;
                                  date1.set({
                                    hour: time2.get("hour"),
                                    minute: time2.get("minute"),
                                  });
                                  let setDate = time.toDate();
                                  setSlot(slot);
                                  setProfile(true, setDate, time, slot.profile);
                                  this.props.history.replace({
                                    pathname: "/menu/food",
                                  });
                                }}>
                                {slot.label}
                              </button>
                            );
                          })}
                        </React.Fragment>
                      );
                    })
                  : null}

                {/* {this.state.timeslots[this.state.day].map((slot) => {
              if (slot.slot === "9:00am - 9:30am" && this.state.day === "weekend") {
                return <React.Fragment>
                  <span>Breakfast Menu</span>
                  <button
                    disabled={slot.time.isBefore(this.state.timeNow) && this.state.timeNow.isSame(this.state.time, "day")}
                    style={slot.time.isBefore(this.state.timeNow) && this.state.timeNow.isSame(this.state.time, "day") ? { cursor: "pointer", opacity: 0.5 } : { cursor: "pointer" }}
                    onClick={() => {
                      let date1 = this.state.time;
                      let time2 = slot.time;
                      date1.set({
                        hour: time2.get("hour"),
                        minute: time2.get("minute"),
                      });
                      let setDate = this.state.time.toDate();
                      this.context.setSlot(slot);
                      this.context.setMenu(true, setDate, this.state.time);
                      if (this.context.isMobile) {
                        this.props.history.replace({
                          pathname: "/menu/food",
                        });
                      }
                      else {
                        this.props.onClose();
                      }
                    }}
                  >
                    {slot.slot}
                  </button>
                </React.Fragment>
              }
              else if (slot.slot === "11:30am - 12:00pm" && this.state.day === "weekend") {
                return <React.Fragment>
                  <span>Brunch Menu</span>
                  <button
                    disabled={slot.time.isBefore(this.state.timeNow) && this.state.timeNow.isSame(this.state.time, "day")}
                    style={slot.time.isBefore(this.state.timeNow) && this.state.timeNow.isSame(this.state.time, "day") ? { cursor: "pointer", opacity: 0.5 } : { cursor: "pointer" }}
                    onClick={() => {
                      let date1 = this.state.time;
                      let time2 = slot.time;
                      date1.set({
                        hour: time2.get("hour"),
                        minute: time2.get("minute"),
                      });
                      let setDate = this.state.time.toDate();
                      this.context.setSlot(slot);
                      this.context.setMenu(true, setDate, this.state.time);
                      if (this.context.isMobile) {
                        this.props.history.replace({
                          pathname: "/menu/food",
                        });
                      }
                      else {
                        this.props.onClose();
                      }
                    }}
                  >
                    {slot.slot}
                  </button>
                </React.Fragment>
              }
              else if (slot.slot === "6:00pm - 6:30pm" && this.state.day === "weekend" || slot.slot === "11:00am - 11:30am" && this.state.day === "weekday") {
                return <React.Fragment>
                  <span>Ala Carte Menu</span>
                  <button
                    disabled={slot.time.isBefore(this.state.timeNow) && this.state.timeNow.isSame(this.state.time, "day")}
                    style={slot.time.isBefore(this.state.timeNow) && this.state.timeNow.isSame(this.state.time, "day") ? { cursor: "pointer", opacity: 0.5 } : { cursor: "pointer" }}
                    onClick={() => {
                      let date1 = this.state.time;
                      let time2 = slot.time;
                      date1.set({
                        hour: time2.get("hour"),
                        minute: time2.get("minute"),
                      });
                      let setDate = this.state.time.toDate();
                      this.context.setSlot(slot);
                      this.context.setMenu(true, setDate, this.state.time);
                      if (this.context.isMobile) {
                        this.props.history.replace({
                          pathname: "/menu/food",
                        });
                      }
                      else {
                        this.props.onClose();
                      }
                    }}
                  >
                    {slot.slot}
                  </button>
                </React.Fragment>
              }
              else {
                return (
                  <button
                    disabled={slot.time.isBefore(this.state.timeNow) && this.state.timeNow.isSame(this.state.time, "day")}
                    style={slot.time.isBefore(this.state.timeNow) && this.state.timeNow.isSame(this.state.time, "day") ? { cursor: "pointer", opacity: 0.5 } : { cursor: "pointer" }}
                    onClick={() => {
                      let date1 = this.state.time;
                      let time2 = slot.time;
                      date1.set({
                        hour: time2.get("hour"),
                        minute: time2.get("minute"),
                      });
                      let setDate = this.state.time.toDate();
                      this.context.setSlot(slot);
                      this.context.setMenu(true, setDate, this.state.time);
                      if (this.context.isMobile) {
                        this.props.history.replace({
                          pathname: "/menu/food",
                        });
                      }
                      else {
                        this.props.onClose();
                      }
                    }}
                  >
                    {slot.slot}
                  </button>
                );
              }
            })} */}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}
