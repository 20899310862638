import React, { Component } from "react";
import FilterResults from "react-filter-search";
import MenuItem from "./MenuItem";
import { Drawer, Button } from "antd";
import { HashLink as Link } from "react-router-hash-link";
import { CartContext } from "./contexts/CartContext";
import { outlet, outletColor } from "../config";
import scrollToComponent from "react-scroll-to-component";
export default class Desserts extends Component {
  static contextType = CartContext;
  state = {};
  render() {
    return (
      <div id="menu" className="dessert-menu">
        {this.props.desserts.sort((a, b) => {
          if (a.order < b.order) {
            return -1;
          } else {
            return 1;
          }
        }).map((item) => {
          return (
            <MenuItem
              update={this.props.update}
              data={item}
              key={item.id}
              image={true}
            ></MenuItem>
          );
        })}
      </div>
    );
  }
}
