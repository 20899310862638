import React, { Component, createContext } from "react";
import { outlet } from "../../config";
import moment from "moment";
import { db } from "../firebase/Firebase";
export const CartContext = createContext();

export default class CartContextProvider extends Component {
  state = {
    cart: [],
    outlet: outlet,
    entered: false,
    prevCart: [],
    loaded: false,
    mode: "takeout",
    receiptInfo: {},
    time: "",
    timeslot: "",
    address: "",
    dateValue: "",
    deliveryFee: 0,
    freeDelivery: false,
    menu: "",
    menus: [],
    profiles: [],
    selectedMenuData: { categories: [] },
    selectedProfileData: { menus: [] },
    deliveryDisabled: false,
    isWeekend: false,
    later: false,
    momentTime: "",
    isMobile: window.innerWidth > 600 ? false : true,
    isTablet:
      window.innerWidth > 600 && window.innerWidth < 1000 ? true : false,
  };
  componentDidMount() {
    db.collection("Menus")
      .where("outlet", "==", outlet)
      .get()
      .then((querySnapShot) => {
        let menus = [];
        querySnapShot.forEach((item) => {
          let itemData = item.data();
          menus.push({ ...itemData, id: item.id });
        });
        this.setState(
          {
            menus,
          },
          () => {
            this.setState({
              loaded: true,
            });
          }
        );
      });

    db.collection("Profiles")
      .where("outlet", "==", outlet)
      .get()
      .then((querySnapShot) => {
        let profiles = [];
        querySnapShot.forEach((item) => {
          let itemData = item.data();
          profiles.push({ ...itemData, id: item.id });
        });
        this.setState(
          {
            profiles,
          },
          () => {
            this.setState({
              loaded: true,
            });
          }
        );
      });
  }
  setSlot = (slot) => {
    this.setState({ timeslot: slot.label, momentTime: slot.start });
  };
  setProfile = (
    later,
    inputDate = new Date(),
    timeString = moment.utc(new Date()).local().add("h", 1),
    profile
  ) => {
    let formattedTime = moment(timeString).format("DD MMM YY hh:mm A");
    let selectedProfileData = this.state.profiles.find(
      (item) => item.id === profile
    );
    console.log(selectedProfileData);
    this.setState({
      profile,
      selectedProfileData,
      dateValue: timeString,
      time: formattedTime,
      later,
    });

    /*  let highTeaStart = new Date(inputDate.getTime()).setHours(15, 30);
     let highTeaEnd = new Date(inputDate.getTime()).setHours(17, 30);
     let weekendBrunchStart = new Date(inputDate.getTime()).setHours(11, 30);
     let weekendBrunchEnd = new Date(inputDate.getTime()).setHours(16, 30);
     let weekendBreakStart = new Date(inputDate.getTime()).setHours(8, 30);
     let weekendBreakEnd = new Date(inputDate.getTime()).setHours(11, 29);
     let festiveStart = new Date(2021, 4, 13, 0, 0);
     let festiveEnd = new Date(2021, 4, 13, 23, 59);
     let festiveStart1 = new Date(2021, 4, 26, 0, 0);
     let festiveEnd2 = new Date(2021, 4, 26, 23, 59);
     let currentDate = inputDate;
     let dineIn = new Date(2021, 5, 21, 0, 0);
     let ph1 = currentDate.getTime() >= festiveStart.getTime() && currentDate.getTime() <= festiveEnd.getTime();
     let ph2 = currentDate.getTime() >= festiveStart1.getTime() && currentDate.getTime() <= festiveEnd2.getTime();
     let dayOfWeek = currentDate.getDay();
     let isWeekend = dayOfWeek == 6 || dayOfWeek == 0;
     let formattedTime = moment(timeString).format("DD MMM YY hh:mm A");
     if (false) {
       if (later) {
         this.setState({
           menu: "high-tea",
           dateValue: timeString,
           time: formattedTime,
           isWeekend,
           later
         });
       }
       else {
         this.setState({
           menu: "high-tea",
           isWeekend,
           later
         });
       }
       return;
     } else if (isWeekend) {
       if (currentDate >= weekendBreakStart && currentDate <= weekendBreakEnd) {
         console.log("WB");
         if (later) {
           this.setState({
             menu: "weekend-breakfast",
             dateValue: timeString,
             time: formattedTime,
             isWeekend,
             later
           });
         }
         else {
           this.setState({
             menu: "weekend-breakfast",
             isWeekend,
             later
           });
         }
         return;
       } else if (
         currentDate >= weekendBrunchStart &&
         currentDate <= weekendBrunchEnd
       ) {
         if (later) {
           this.setState({
             menu: "weekend-brunch",
             dateValue: timeString,
             time: formattedTime,
             isWeekend,
             later
           });
         }
         else {
           this.setState({
             menu: "weekend-brunch",
             isWeekend,
             later
           });
         }
         return;
       }
       else {
         if (later) {
           this.setState({
             menu: "alacarte",
             dateValue: timeString,
             time: formattedTime,
             isWeekend,
             later
           });
         }
         else {
           this.setState({
             menu: "alacarte",
             isWeekend,
             later
           });
         }
         return;
       }
     }
     else {
       if (later) {
         this.setState({
           menu: "alacarte",
           dateValue: timeString,
           time: formattedTime,
           isWeekend,
           later
         });
       }
       else {
         this.setState({
           menu: "alacarte",
           isWeekend,
           later
         });
       }
       return;
     } */
  };
  setMode = (mode, value) => {
    if (mode === "delivery") {
      this.setState(
        {
          mode,
          deliveryFee: value.deliveryFee,
          addressDetails: value.details,
          address: value.address,
          calcFee: value.calcFee,
          entered: false,
        },
        () => {
          this.amountCheck();
        }
      );
    } else {
      this.setState(
        {
          mode,
          entered: true,
          deliveryFee: 0,
          address: "",
        },
        () => {
          this.amountCheck();
        }
      );
    }
  };
  saveCart = (cart) => {
    this.setState({
      prevCart: cart,
    });
  };
  getQty = () => {
    if (this.state.cart.length == 0) {
      return 0;
    } else {
      return this.state.cart
        .map((item) => {
          return item.qty;
        })
        .reduce((acc = 0, cur) => {
          return acc + cur;
        });
    }
  };
  addToCart = (item) => {
    let { cart } = this.state;
    let cartIds = cart.map((cartItem) => {
      return cartItem.id;
    });
    if (this.state.cart.length === 0) {
      this.setState({
        cart: [item],
      });
    } else if (cartIds.indexOf(item.id) == -1 || item.hasOptions) {
      this.setState({
        cart: [...cart, item],
      });
    } else {
      let newCart = cart.map((cartItem) => {
        if (cartItem.id === item.id) {
          let newCartItem = cartItem;
          newCartItem.qty += item.qty;
          return cartItem;
        } else {
          return cartItem;
        }
      });
      this.setState({
        cart: newCart,
      });
    }
  };
  deleteItem = (itemToDelete) => {
    let { cart } = this.state;
    this.setState(
      {
        cart: cart.filter((item) => {
          return item.id !== itemToDelete;
        }),
      },
      () => this.amountCheck()
    );
  };
  updateCart = (update) => {
    let { cart } = this.state;
    let cartIds = cart.map((item) => {
      return item.id;
    });
    let newCart = cart;

    newCart = cart.map((item) => {
      if (update.id == item.id) {
        return update;
      } else {
        return item;
      }
    });

    if (update.qty === 0) {
      let index = newCart
        .map((item) => {
          return item.id;
        })
        .indexOf(update.id);
      newCart.splice(index, 1);
    }

    this.setState({
      cart: newCart,
    });
  };

  clearCart = () => {
    let cartCopy = this.state.cart;
    this.setState(
      {
        prevCart: cartCopy,
      },
      () => {
        this.setState({
          cart: [],
          deliveryFee: 0,
        });
      }
    );
  };

  calcAmount = (cartData) => {
    let newData = [...cartData];
    newData = newData.map((item) => {
      item.totalPrice = item.price;
      item.options.map((option) => {
        item.totalPrice += option.topup;
      });
      return item;
    });
    let priceSum = newData.map((data) => {
      return data.totalPrice * data.qty;
    });
    if (priceSum.length !== 0) {
      let calcAmt = priceSum.reduce((acc, cur) => {
        return acc + cur;
      });

      return calcAmt;
    } else {
      return 0;
    }
  };

  saveReceipt = (info) => {
    this.setState({
      receiptInfo: info,
    });
  };
  amountCheck = () => {
    let freeDelivery = false;
    if (outlet === "CM-PB") {
      if (this.calcAmount(this.state.cart) >= 200.0) {
        freeDelivery = true;
      }
    }
    if (this.state.mode === "takeout" || freeDelivery) {
      console.log("3");
      this.setState({ deliveryFee: 0 });
    } else {
      console.log("2");
      this.setState({
        deliveryFee: this.state.calcFee,
      });
    }
  };
  render() {
    return (
      <CartContext.Provider
        value={{
          ...this.state,
          getQty: this.getQty,
          addToCart: this.addToCart,
          amountCheck: this.amountCheck,
          deleteItem: this.deleteItem,
          setMode: this.setMode,
          clearCart: this.clearCart,
          saveReceipt: this.saveReceipt,
          setProfile: this.setProfile,
          setSlot: this.setSlot,
        }}>
        {this.props.children}
      </CartContext.Provider>
    );
  }
}
