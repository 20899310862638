import React, { Component } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faEdit } from "@fortawesome/free-solid-svg-icons";
import { CartContext } from "./contexts/CartContext";
import { Link } from "react-router-dom";
import Modal from "./Modal";
import ItemPage from "./ItemPage";

library.add(faPlus, faMinus, faEdit);

export default class MenuItem extends Component {
  static contextType = CartContext;
  state = {
    showEdit: false,
    qty: 0,
    edit: "",
    hasImage: true,
    isOpen: false,
  };
  updateQty = (qty, action) => {
    if (action == "add") {
      this.setState({
        qty: this.state.qty + qty,
      });
    } else if (this.state.qty == 0) {
      return;
    } else {
      this.setState({
        qty: this.state.qty - qty,
      });
    }
  };
  toggleEdit = () => {
    this.setState({
      showEdit: !this.state.showEdit,
    });
  };

  toggleModal = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  render() {
    let { mode, isMobile } = this.context;
    let { data, image } = this.props;
    return (
      <div className="menu-item">
        <div className="item-desc">
          <Link
            style={{ cursor: "default" }}
            to={
              isMobile
                ? {
                    pathname: "/menu/item-page",
                    state: { ...this.props.data, gotImage: true },
                  }
                : null
            }
          >
            <h1 className="menu-item-name">
              {data.name}
              {data.category === "wine" ? (
                <React.Fragment>
                  <span
                    style={data.redWine ? { color: "#a31818" } : null}
                    className="collection"
                  >
                    {data.wineCollection}
                  </span>
                  <span className="collection">{data.wineCountry}</span>
                </React.Fragment>
              ) : data.category === "beer" || data.category === "others" ? (
                <span style={{ fontSize: "16px" }} className="collection">
                  {data.beerAmount}
                </span>
              ) : null}
              {data.pax ? <h4>{data.pax}</h4> : null}
            </h1>
          </Link>
          <div className="price-add">
            <span className="menu-item-price">
              {mode === "delivery" ? data.pricet : data.pricet}
            </span>
            {!isMobile ? (
              <button
                onClick={() => {
                  this.setState({ isOpen: true });
                }}
                style={{ cursor: "pointer" }}
              >
                ADD
              </button>
            ) : null}

            {this.state.isOpen ? (
              <Modal onClose={this.toggleModal}>
                <ItemPage
                  isOpen={true}
                  onClose={this.toggleModal}
                  data={{ ...data, gotImage: true }}
                ></ItemPage>
              </Modal>
            ) : null}
          </div>
        </div>
        {data.url ? (
          <Link
            to={
              isMobile
                ? {
                    pathname: "/menu/item-page",
                    state: { ...this.props.data, gotImage: true },
                  }
                : null
            }
          >
            <div className="menu-item-img">
              <img
                src={data.url}
                className="thumbnail"
                onError={() => {
                  this.setState({
                    hasImage: false,
                  });
                }}
                style={!this.state.hasImage ? { visibility: "hidden" } : null}
              ></img>
            </div>
          </Link>
        ) : null}
      </div>
    );
  }
}
