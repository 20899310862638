import React from "react";
import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import { CartContext } from "./contexts/CartContext";
import CardSection from "./CardSection";
import { message } from "antd";
import { useHistory } from "react-router-dom";
import { config } from "@fortawesome/fontawesome-svg-core";
import { outletContact, outletColor } from "../config";
import moment from "moment";

class CheckoutForm extends React.Component {
  static contextType = CartContext;
  state = {
    disabled: false,
    submit: "Confirm Payment",
  };
  handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    //this.context.setMenu(false);
    if (!moment().add("h", 1).isBefore(this.context.dateValue)) {
      message.error(
        "Some time has passed since you selected your delivery time slot, please a select a new one."
      );
      return;
    }

    const { stripe, elements } = this.props;
    let { name, email, mobile, time } = this.props.contact;

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (name === "" || email === "" || mobile === "") {
      message.error("Please fill in the required fields.");
      return;
    }
    this.setState({
      disabled: true,
      submit: "Submitting...",
    });
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);

    if (result.error) {
      // Show error to your customer.
      console.log(result.error.message);
      message.error(result.error.message);
    } else {
      // Send the token to your server.
      // This function does not exist yet; we will define it in the next step.
      this.stripeTokenHandler(result.token);
    }
  };

  stripeTokenHandler = (token) => {
    let {
      cart,
      mode,
      address,
      deliveryFee,
      clearCart,
      outlet,
      saveReceipt,
      time,
      dateValue,
      addressDetails,
      timeslot,
    } = this.context;
    let orderData = {
      withDiscount: false,
      token: token.id,
      cart: cart,
      outlet: outlet,
      mode: mode,
      address: address,
      deliveryFee: deliveryFee,
      time: time,
      createdAt: new Date(),
      orderDateTime: dateValue,
      timeslot,
      addressDetails,
      ...this.props.contact,
    };
    let dateTime = new Date();
    let orderDate = `${dateTime.getDate()}/${
      dateTime.getMonth() + 1
    }/${dateTime.getFullYear()} ${dateTime.getHours()}:${dateTime.getMinutes()}`;
    // Use fetch to send the token ID and any other payment data to your server.
    // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
    // https://us-central1-ordering-eaa87.cloudfunctions.net/widgets/new-order
    // http://localhost:5001/ordering-eaa87/us-central1/widgets
    fetch(
      "https://us-central1-ordering-eaa87.cloudfunctions.net/widgets/new-order",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(orderData),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "succeeded") {
          saveReceipt({
            ...this.props.contact,
            orderDate: orderDate,
            address: this.context.address,
            mode: this.context.mode,
            deliveryFee: deliveryFee,
          });
          clearCart();
          this.props.history.replace({
            pathname: "/receipt",
          });
        } else {
          message.error("Something went wrong. Please try again later.");
        }
      });

    // Return and display the result of the charge.
  };
  render() {
    return (
      <form className="checkout-form" onSubmit={this.handleSubmit}>
        <CardSection />
        <button
          to="/menu/receipt"
          style={{ backgroundColor: outletColor }}
          disabled={!this.props.stripe || this.state.disabled}
          className={
            !this.props.stripe || this.state.disabled
              ? "place-order order-button"
              : "place-order order-button"
          }
          onClick={this.handleSubmit}>
          {this.state.submit}
        </button>
        {this.context.mode === "delivery" ? (
          <span id="note2">
            It will take approximately 1 hour for your order to arrive at your
            place. Call us if you want to have it delivered at a later time.
            <br></br>
            {outletContact}
          </span>
        ) : (
          <span id="note2">
            If you wish to collect your order at a later time, please call us.
            <br></br>
            {outletContact}
          </span>
        )}
      </form>
    );
  }
}

export default function InjectedCheckoutForm(props) {
  console.log(props.contact);
  let history = useHistory();
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm
          stripe={stripe}
          elements={elements}
          contact={props.contact}
          history={history}
        />
      )}
    </ElementsConsumer>
  );
}
