import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShoppingCart,
  faHome,
  faClock,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { CartContext } from "./contexts/CartContext";
import { outlet, outletColor } from "../config";
import { Badge } from "antd";
library.add(faShoppingCart, faHome, faClock);

class NavBar extends Component {
  static contextType = CartContext;
  render() {
    let quantity = this.context.getQty();
    let selectedOutlet = outlet;
    return (
      <div className="nav" style={{ backgroundColor: outletColor }}>
        <div className="address">
          {!this.context.isMobile && !this.context.isTablet ? (
            <img
              src={require(`../images/${selectedOutlet}/nav.png`)}
              alt=""
              style={
                selectedOutlet === "CM-PB"
                  ? {
                      width: "auto",
                      height: "3em",
                      marginRight: 24,
                      filter: "invert(1)",
                    }
                  : { width: "auto", height: "3em", marginRight: 24 }
              }
            />
          ) : null}
          {!this.context.isMobile ? (
            <Link
              style={
                outlet === "TCS"
                  ? { border: "2px solid black", color: "black" }
                  : { backgroundColor: "white", borderRadius: "0.3em" }
              }
              className="home-link"
              onClick={() => {
                this.context.clearCart();
              }}
              to="/choice">
              <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon> &nbsp; ETD{" "}
              {this.context.time}
            </Link>
          ) : (
            <div
              style={{
                marginBlockStart: "1em",
                display: "flex",
                justifyContent: "flex-start",
                width: "100%",
              }}>
              <Link
                style={
                  outlet === "TCS"
                    ? { border: "2px solid black", color: "black" }
                    : { backgroundColor: "white", borderRadius: "0.3em" }
                }
                className="home-link"
                onClick={() => {
                  this.context.clearCart();
                  this.props.onClose();
                }}
                to={this.context.isMobile ? "/choice" : "/menu/food"}>
                <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon> &nbsp; ETD{" "}
                {this.context.time}
              </Link>
            </div>
          )}
        </div>
        <Badge
          count={quantity}
          showZero={true}
          style={
            this.context.outlet === "CM-PB"
              ? { backgroundColor: "#fff", color: "#000", fontWeight: "bold" }
              : {
                  backgroundColor: "#bc9b5d",
                  color: "#fff",
                  fontWeight: "bold",
                }
          }>
          <Link
            style={
              this.context.outlet === "CM-PB"
                ? { color: "#fff", fontWeight: "bold" }
                : {
                    backgroundColor: "#bc9b5d",
                    color: "#fff",
                    fontWeight: "bold",
                  }
            }
            to="/menu/cart">
            <FontAwesomeIcon icon={faShoppingCart}></FontAwesomeIcon>
          </Link>
        </Badge>
      </div>
    );
  }
}

export default withRouter(NavBar);
