import React, { Component } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faEdit } from "@fortawesome/free-solid-svg-icons";
import { CartContext } from "./contexts/CartContext";
import { withRouter } from "react-router-dom";
import { outletColor } from "../config";
import { message } from "antd";
library.add(faPlus, faMinus, faEdit);
class ItemPage extends Component {
  static contextType = CartContext;
  state = {
    qty: 1,
    edit: "",
    choices: "",
    choices2: "",
    pizza: false,
    hasImage: true,
    addOn: false,
    addOnText: "add",
    addOnDisabled: false,
    hasOptions: false,
    options: []
  };
  componentDidMount() {
    /*if (this.props.location.state === undefined) {
      this.props.history.replace("/menu/food");
    }*/
    /* {state.choices !== undefined ? (
                <div className="choices">
                  {state.choices.map((choice) => {
                    return (
                      <div>
                        <label htmlFor="choice">{choice}</label>
                        <input
                          value={choice}
                          onChange={this.onChange}
                          type="radio"
                          name="choice"
                          id="choice"
                        />
                      </div>
                    );
                  })}
                </div>
              ) : null}*/
  }
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  updateQty = (qty, action) => {
    if (action == "add") {
      this.setState({
        qty: this.state.qty + qty,
      });
    } else if (this.state.qty == 1) {
      return;
    } else {
      this.setState({
        qty: this.state.qty - qty,
      });
    }
  };
  addRemoveAddon = (data) => {
    if (this.state[data.label]) {
      alert("Please select an option!")
      return;
    }
    let newOptions = [...this.state.options];
    let added = newOptions.some(item => {
      return item.label === data.label;
    })
    if (added) {
      newOptions.splice(newOptions.findIndex(item => {
        return item.label === data.label;
      }), 1);
      this.setState({ options: newOptions });
    }
    else {
      newOptions = [...newOptions, { type: "addon", label: data.label, topup: data.topup, value: data.choices.length === 1 ? data.label : this.state[data.label] }];
      this.setState({ options: newOptions });
    }
  }
  onChoiceChange = (e, topupValue, optionType, addOnLabel) => {
    this.setState({ [e.target.id]: e.target.value });
    let newOptions = [...this.state.options];
    let added = newOptions.some(item => {
      return item.label === e.target.id;
    })
    console.log(added);
    if (added) {
      newOptions.map(item => {
        if (item.label === e.target.id) {
          item.value = e.target.value;
          return item;
        }
        else {
          return item;
        }
      })
      this.setState({ options: newOptions });
    }
    else {
      if (addOnLabel === "Coffee") {
        newOptions.splice(newOptions.findIndex(item => {
          return item.label === "Juice";
        }), 1);
      }
      else if (addOnLabel === "Juice") {
        newOptions.splice(newOptions.findIndex(item => {
          return item.label === "Coffee";
        }), 1);
      }
      newOptions = [...newOptions, { type: optionType, label: e.target.id, value: optionType === "choice" ? e.target.value : `${addOnLabel}:${e.target.value}`, topup: topupValue }];
      this.setState({ options: newOptions });
    }
  }
  onNoneChange = (data) => {
    let newOptions = [...this.state.options];
    let added = newOptions.some(item => {
      return item.label === data.label;
    })
    if (added) {
      newOptions.splice(newOptions.findIndex(item => {
        return item.label === data.label;
      }), 1);
      this.setState({ options: newOptions });
    }
  }
  render() {
    let { addToCart, mode, isMobile } = this.context;
    let { state } = this.props.location;
    if (!isMobile) {
      state = this.props.data;
    }
    if (!state.options || state.options === "optnil") {
      state.options = [];
    }
    console.log(state);
    let choiceTypes = state.options.filter(item => {
      return item.type === "choice";
    })
    let addonTypes = state.options.filter(item => {
      return item.type === "addon";
    })
    let options = [...choiceTypes, ...addonTypes];
    return (
      <div className="menu-item-page">
        {state !== undefined ? (
          <React.Fragment>
            {state.gotImage ? (
              <img
                onError={() => {
                  this.setState({
                    hasImage: false,
                  });
                }}
                src={state.url}
                style={
                  !this.state.hasImage
                    ? { visibility: "hidden" }
                    : state.category === "beer" ||
                      state.category === "wine" ||
                      state.category === "others"
                      ? { width: "30%" }
                      : null
                }
              ></img>
            ) : null}

            <div className="item-details">
              <h1 className="order-title">{state.name}</h1>
              <div className="item-description">
                <h4 className="sub-title">
                  {state.description}
                  <br></br>

                  {state.compliment}
                  {state.category === "comfort" ? (
                    <React.Fragment>
                      <br></br>Upgrade to a value set to enjoy a brownie and a
                      choice of latte OR long black OR iced lemon tea (indicate
                      under special request)
                    </React.Fragment>
                  ) : null}
                  {state.includes ? (
                    <div className="includes">
                      {state.availability.map((item) => {
                        return <h4>{item}</h4>;
                      })}
                      <br></br>
                      <h4>{state.soup}</h4>
                      <br></br>
                      {state.mains.map((item) => {
                        return <h4>{item}</h4>;
                      })}
                      <br></br>
                      {state.bowls.map((item) => {
                        return <h4>{item}</h4>;
                      })}
                      <br></br>
                      <h4>{state.cake}</h4>
                      <br />
                      {state.beverages.map((item) => {
                        return <h4>{item}</h4>;
                      })}
                    </div>
                  ) : null}
                  {state.desc ? (
                    <React.Fragment>
                      {state.desc.map((line) => {
                        return <h4>{line}</h4>;
                      })}
                    </React.Fragment>
                  ) : null}
                </h4>
                <h2>${mode === "delivery" ? state.pricet : state.pricet}</h2>
              </div>
              {options.length !== 0 ? options.map(option => {
                return <div className="choices">
                  <h4 style={{ fontWeight: "bold" }}>{option.label}{option.type === "addon" ? ` +$${option.topup}` : "*"}</h4>
                  <React.Fragment>{option.choices.map((choice, index) => {
                    return (
                      <div className="choice-box">
                        <label htmlFor={option.label}>{choice}</label>
                        <input
                          type="radio"
                          name={option.label}
                          id={option.label}
                          onChange={(e) => this.onChoiceChange(e, option.topup, option.type, option.label)}
                          value={choice}
                        />
                      </div>
                    );
                  })}{option.type === "addon" ? <div className="choice-box">
                    <label htmlFor={option.label}>None</label>
                    <input
                      type="radio"
                      name={option.label}
                      id={option.label}
                      onChange={() => this.onNoneChange(option)}
                      value={"None"}
                    />
                  </div> : null}</React.Fragment>
                </div>
              }) : null}
              <div className="request">
                <h5>special request (if any)</h5>
                <textarea
                  id="edit"
                  onChange={this.onChange}
                  value={this.state.edit}
                ></textarea>
              </div>
            </div>

            <div className="update-qty">
              <button
                className="minus"
                style={{ cursor: "pointer" }}
                onClick={() => this.updateQty(1, "minus")}
              >
                <FontAwesomeIcon icon={faMinus} size="2x"></FontAwesomeIcon>
              </button>
              <h3>{this.state.qty}</h3>
              <button
                className="add"
                style={{ cursor: "pointer" }}
                onClick={() => this.updateQty(1, "add")}
              >
                <FontAwesomeIcon icon={faPlus} size="2x"></FontAwesomeIcon>
              </button>
            </div>
            <button
              id="cartAdd"
              className="order-button"
              style={{ backgroundColor: outletColor, cursor: "pointer" }}
              onClick={() => {
                let choiceOptions = this.state.options.filter(item => {
                  return item.type === "choice";
                })
                console.log(choiceOptions);
                if (choiceOptions.length >= choiceTypes.length) {
                  addToCart({
                    name: state.name,
                    price: parseInt(state.pricet),
                    id: state.id,
                    wineCollection: state.wineCollection || "",
                    edit: this.state.edit,
                    qty: this.state.qty,
                    options: this.state.options,
                    hasOptions: this.state.options.length !== 0 ? true : false
                  });
                  if (!isMobile) {
                    this.props.onClose();
                  } else {
                    this.props.history.replace("/menu/food");
                  }
                }
                else {
                  message.warning("Please select the choices!");
                }
              }}
            >
              Add To Cart
            </button>
            { isMobile ? <button
              id="cartAdd"
              className="order-button"
              style={{ backgroundColor: "#eceff1", cursor: "pointer", marginTop: 8, color: "#37474F" }}
              onClick={() => {
                if (!isMobile) {
                  this.props.onClose();
                } else {
                  this.props.history.replace("/menu/food");
                }
              }}
            >
              Back To Menu
            </button> : null}
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}
export default withRouter(ItemPage);
